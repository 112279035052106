import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "AboutMe",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/about/AboutMe.vue")
  },
  {
    path: "/resume",
    name: "Resume",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/resume/ResumeView.vue")
  },
  {
    path: "/blogs",
    name: "BlogList",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/blog/BlogList.vue")
  },
  {
    path: "/contact",
    name: "ContactMe",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/contact/ContactMe.vue")
  }
  // {
  //   path: "/resume",
  //   name: "Resume",
  //   meta: {
  //     layout: "extra",
  //   },
  //   component: Resume,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active"
});

export default router;
