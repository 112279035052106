import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/styles/global.scss";

import { registerGlobalComponent } from "./utils/import";
import VueParticles from "vue-particles";
import AOS from "aos";
import "aos/dist/aos.css";

// Optionally install t
const app = createApp(App);
registerGlobalComponent(app);
app.use(router);
app.use(VueParticles);
app.use(AOS);
app.mount("#app");
